import { useEffect, useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  LinearProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { appList, appListResult } from '../data/actions/app'
import { sendNotification, getTokenNumbers, sendNotificationResult } from '../data/actions/notification'
import { logout } from '../utils/preferences'
import { ConfirmationModal } from '../components/modal'
import { CustomSnackBar } from 'src/components/custom';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Notification = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const appListResponse = appListResult.use()
  const sendNotificationResponse = sendNotificationResult.use()


  const [apps, setApps] = useState([])
  const [selectedApp, setSelectedApp] = useState(null)
  const [sendToAllChecked, setSendToAllChecked] = useState(true)
  const [response, setResponse] = useState({
    isSuccess: false,
    message: null
  });
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = async (values) => {
    let _userIds
    if (!sendToAllChecked && values.userIds) {
      _userIds = values.userIds.split(';')
    }
    await sendNotification(values.title, values.body, selectedApp.id, _userIds)
    setOpen(false);
  }

  const handleUserIdsChange = async (e) => {
    if (selectedApp != null) {
      let val = e.target.value
      if (val.slice(-1) == ";") {
        let userIds = val.split(";")
        await getTokenNumbers(selectedApp.id, userIds)
      }
    }
  }

  const processAppList = () => {
    if (appListResponse) {
      if (appListResponse.loading) {
        // show loading
      }
      else {
        console.log('appListResponse', appListResponse)
        if (appListResponse.result) {
          const _appListRes = appListResponse.result

          if (_appListRes.status === 200) {
            if (_appListRes.data && _appListRes.data.success) {
              setApps(_appListRes.data.data)
            }

            // api returned error?
          }
          else {
            if (_appListRes.status === 403) {
              // token expired or malformed
              // redirect to login

              logout()
              navigate('/login', { replace: true });
            }

            // other errors
          }
        }
      }
    }

    // error
  }

  const processNotification = () => {
    if (sendNotificationResponse) {
      if (sendNotificationResponse.loading) {
        // show loading
      }
      else {
        console.log('appListResponse', sendNotificationResponse)
        if (sendNotificationResponse.result) {
          const _sendNotificationRes = sendNotificationResponse.result
          if (_sendNotificationRes.status === 200) {
            if (_sendNotificationRes.data && _sendNotificationRes.data.success) {
              setResponse({ isSuccess: true, message: "İşlem başarılı" });
            }
            else {
              // api returned error?
              setResponse({ isSuccess: false, message: _sendNotificationRes.data.message });
            }
          }
          else {
            if (_sendNotificationRes.status === 403) {
              // token expired or malformed
              // redirect to login

              logout()
              navigate('/login', { replace: true });
            }
            // other errors
            setResponse({ isSuccess: false, message: _sendNotificationRes.data.message });
          }
        }
      }
    }

    // error
  }

  const didMount = async () => {
    await appList()
  }

  useEffect(() => {
    processAppList()
  }, [appListResponse.result])

  useEffect(() => {
    processNotification()
  }, [sendNotificationResponse.result])

  useEffect(() => {
    didMount()
  }, [])

  return (
    <>
      <Helmet>
        <title>Bildirim Gönder</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <div>
          <Formik
            initialValues={{
              title: '',
              body: '',
              userIds: null
            }}
            validationSchema={Yup.object().shape({
              title: Yup.string().max(255, 'maksimum karakter sayısı 255').required('başlık alanı boş geçilemez'),
              body: Yup.string().max(255, 'maksimum karakter sayısı 255').required('metin alanı boş geçilemez')
            })}
            onSubmit={async (values) => {
              if (selectedApp != null && values.userIds != null) {
                let data = values.userIds.split(";")
                await getTokenNumbers(selectedApp.id, data)
              }
              setOpen(true);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <>
                <form onSubmit={handleSubmit} style={{ paddingLeft: 24, paddingRight: 24 }}>
                  <Box sx={{ mb: 3, mt: 3 }}>
                    <Typography
                      color="textPrimary"
                      variant="h4"
                    >
                      Bildirim Gönder
                    </Typography>
                  </Box>
                  <TextField
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                    sx={{ width: '50%' }}
                    label="Başlık"
                    margin="normal"
                    name="title"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.title}
                    variant="outlined"
                  />
                  <div style={{ clear: 'both' }} />
                  <TextField
                    error={Boolean(touched.body && errors.body)}
                    helperText={touched.body && errors.body}
                    sx={{ width: '50%' }}
                    multiline
                    rows={3}
                    label="Metin"
                    margin="normal"
                    name="body"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.body}
                    variant="outlined"
                  />
                  <div style={{ clear: 'both' }} />
                  <Typography
                    color="textPrimary"
                    variant="h4"
                  >
                    Uygulama Listesi
                  </Typography>
                  <br />
                  {
                    apps.map((app, index) => {
                      const selected = selectedApp && selectedApp.id === app.id

                      return (
                        <Button
                          key={app.id}
                          variant="contained"
                          color={selected ? "secondary" : "inherit"}
                          size="large"
                          startIcon={app.iconUrl ? <img src={app.iconUrl} /> : null}
                          style={index > 0 ? { marginLeft: 5,marginBottom:10} : {marginBottom:10 }}
                          onClick={async () => {
                            if (selected) {
                              setSelectedApp(null)
                            }
                            else {
                              setSelectedApp(app)
                            }
                          }}
                        >
                          {app.name}
                        </Button>
                      )
                    })
                  }
                  <br />
                  <Box sx={{ py: 2 }}>
                    <FormGroup>
                      <FormControlLabel control={
                        <Checkbox

                          checked={sendToAllChecked}
                          onChange={(event) => setSendToAllChecked(event.target.checked)}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                        label={`Uygulamadaki tüm kullanıcılara gönder (${selectedApp?.numberOfTokens ?? 0})`} />
                    </FormGroup>
                    {
                      !sendToAllChecked ?
                        <>
                          {/* <Typography
                            color="secondary"
                            variant="h6"
                          >
                            {`Toplam (${sendNotificationResponse.numberOfTokens})`}
                          </Typography> */}
                          <TextField
                            error={Boolean(touched.userIds && errors.userIds)}
                            helperText={touched.userIds && errors.userIds}
                            sx={{ width: '100%' }}
                            multiline
                            rows={12}
                            label="Gönderilecek Kullanıcılar"
                            margin="normal"
                            name="userIds"
                            onBlur={handleBlur}
                            disabled={sendNotificationResponse.loading}
                            onChange={(e) => {
                              handleChange(e)
                              // handleUserIdsChange(e)
                            }}
                            value={values.userIds}
                            variant="outlined"
                          />
                          <Typography
                            color="textSecondary"
                            variant="h6"
                          >
                            {"*Birden fazla kullanıcı id'si girerken noktalı virgülle ayırınız"}
                          </Typography>

                        </>
                        : null
                    }
                  </Box>
                  <Box sx={{ py: 2 }}>
                    <Button
                      color="primary"
                      disabled={selectedApp === null || isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      GÖNDER
                    </Button>
                  </Box>
                </form>
                <ConfirmationModal
                  id="ringtone-menu"
                  keepMounted
                  open={open}
                  onCancel={handleClose}
                  onOk={() => handleOk(values)}
                  description={`${!sendToAllChecked
                    ?
                    ` Toplam ${sendNotificationResponse?.numberOfTokens} cihaza 
                    "${values.title}" başlıklı "${values.body}" içerikli bildirim Gönderilecek.`
                    :
                    `Toplam ${selectedApp?.numberOfTokens} cihaza
                    "${values.title}" başlıklı "${values.body}" içerikli  bildirim Gönderilecek. `
                    }
                    Onaylıyormusunuz?`}
                />
              </>
            )}
          </Formik>

          <CustomSnackBar
            open={response.message != null}
            type={response.isSuccess ? 'success' : 'error'}
            handleClose={() => setResponse({ ...response, message: null })}
            message={response.message} />

          {sendNotificationResponse.loading
            ?
            <Box sx={{ width: '100%', position: 'absolute', top: 64, right: 0 }}>
              <LinearProgress sx={{ height: 10 }} color="success" />
            </Box>
            :
            null}
        </div>
      </Box>
    </>
  );
};

export default Notification;
