import * as React from 'react';

import {
    Button, Box
} from '@material-ui/core';

export default function CustomFormButton(props) {
    const { Text, Type, onClick, Icon, disabled, color, ...others } = props;
    return (
        <Box sx={{ px: 0.2 }}>
            <Button type={Type} disabled={disabled} color={color} onClick={onClick} variant="outlined" startIcon={Icon}>
                {Text}
            </Button>
        </Box>
    );
}