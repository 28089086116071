import { entity } from 'simpler-state'
import { post } from '../client'

const loginInitialState = {
    loading: false,
    result: null
}

const loginAction = async (username, password) => {
    const body = {
        username,
        password
    }
    const response = await post('/login', body, false)
    return response
}

export const loginResult = entity(loginInitialState)
export const login = async (username, password) => {
    loginResult.set({
        loading: true,
        result: null
    })

    const result = await loginAction(username, password)

    loginResult.set({
        loading: false,
        result
    })
}