import { entity } from 'simpler-state'
import { get, post } from '../client'

const userListInitialState = {
    loading: false,
    result: null
}

const userListAction = async () => {
    const response = await get('/user', true)
    return response
}



export const userListResult = entity(userListInitialState)

export const userList = async () => {
    userListResult.set({
        loading: true,
        result: null
    })

    const result = await userListAction()

    userListResult.set({
        loading: false,
        result
    })
}

