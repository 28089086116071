import * as React from 'react';
import PropTypes from 'prop-types';

import {
    Button, DialogTitle, DialogContent, DialogActions, Dialog
} from '@material-ui/core';


export default function ConfirmationModal(props) {
    const { onCancel, onOk, open, description, ...other } = props;
    const radioGroupRef = React.useRef(null);


    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };

    const handleCancel = () => {
        onCancel();
    };

    const handleOk = (values) => {
        onOk(values);
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            TransitionProps={{ onEntering: handleEntering }}
            open={open}
            {...other}
        >
            <DialogTitle></DialogTitle>
            <DialogContent dividers>
                {description}
            </DialogContent>
            <DialogActions>
                <Button color='error' autoFocus onClick={handleCancel}>
                    İptal
                </Button>
                <Button onClick={handleOk}>Onayla</Button>
            </DialogActions>
        </Dialog>
    );
}

ConfirmationModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};