import { resetAll } from 'simpler-state'

export const accessTokenKey = 'access_token' 

export const getAccessToken = () => {
    return localStorage.getItem(accessTokenKey)
}

export const setAccessToken = (accessToken) => {
    localStorage.setItem(accessTokenKey, accessToken)
}

export const removeAccessToken = () => {
    localStorage.removeItem(accessTokenKey)
}

export const logout = () => {
    removeAccessToken()
    resetAll()
}