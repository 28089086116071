import { entity } from 'simpler-state'
import { get, post } from '../client'

const sendNotificationInitialState = {
    loading: false,
    result: null,
    numberOfTokens: 0
}

const sendNotificationAction = async (title, message, appId, userIds) => {
    const body = {
        title,
        body: message,
        id: appId,
        userIds
    }
    const response = await post('/notification/send', body, true)
    console.log('NOTIFRESPONSE', response)
    return response
}

const getTokenNumbersAction = async (appId, userIds) => {
    const body = {
        appId,
        userIds
    }

    const response = await post(`/token/count`, body, true)
    return response
}

export const sendNotificationResult = entity(sendNotificationInitialState)

export const sendNotification = async (title, body, appId, userIds) => {
    sendNotificationResult.set({ loading: true, result: null })
    debugger
    try {
        const result = await sendNotificationAction(title, body, appId, userIds)
        sendNotificationResult.set({ loading: false, result, numberOfTokens: 0 })
    }
    catch {

    }
}

export const getTokenNumbers = async (appId, userIds) => {

    sendNotificationResult.set({
        ...sendNotificationInitialState,
        loading: true
    })

    const result = await getTokenNumbersAction(appId, userIds)

    if (result.data && result.data.success) {
        sendNotificationResult.set({
            ...sendNotificationInitialState,
            loading: false,
            numberOfTokens: result.data.data
        })
    }
}