import {
    TextField, Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, InputLabel, Select, MenuItem
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Cancel as CancelIcon, Save as SaveIcon, Add as AddIcon } from '@material-ui/icons';
import CustomFormButton from '../CustomFormButton';

export default function ApplicationForm(props) {
    const { data, onSave, onCancel } = props;

    return (
        <>
            <Formik
                initialValues={data != null ? data : {
                    name: '',
                    storeId: '',
                    fcmServerKey: '',
                    iconUrl: '',
                    platform: 'both',
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().max(255, 'maksimum karakter sayısı 255').
                        required('isim alanı boş geçilemez'),
                    // firmId: Yup.string().max(255, 'maksimum karakter sayısı 255').
                    //     required('firma alanı boş geçilemez')
                })}
                onSubmit={async (values) => {
                    onSave(values)
                    // await login(values.email, values.password)
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                }) => (
                    <form onSubmit={handleSubmit}>
                        <TextField
                            margin="dense"
                            label="Uygulama Adı"
                            type="name"
                            name="name"
                            fullWidth
                            variant="standard"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={Boolean(touched.name && errors.name)}
                            helperText={touched.name && errors.name}
                            value={values.name}
                        />
                        <TextField
                            margin="dense"
                            label="Store ID"
                            name="storeId"
                            type="name"
                            fullWidth
                            variant="standard"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.storeId}
                        />
                        <TextField
                            margin="dense"
                            label="Fcm server key"
                            name='fcmServerKey'
                            type="name"
                            fullWidth
                            variant="standard"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.fcmServerKey}
                        />
                        <TextField
                            margin="dense"
                            label="İkon Url"
                            name='iconUrl'
                            type="name"
                            fullWidth
                            variant="standard"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.iconUrl}
                        />
                        {/* <TextField
                            margin="dense"
                            label="Platform"
                            name='platform'
                            type="name"
                            fullWidth
                            variant="standard"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.platform}
                        /> */}
                        <FormControl component="fieldset" margin='dense' fullWidth
                            style={{ alignItems: 'end' }}>
                            {/* <FormLabel component="legend">Platform</FormLabel> */}
                            <RadioGroup onClick={handleChange} row aria-label="gender" name="platform" value={values.platform} >
                                <FormControlLabel value="Android" control={<Radio color='primary' />} label="Android" />
                                <FormControlLabel value="IOS" control={<Radio color='primary' />} label="IOS" />
                                <FormControlLabel value="both" control={<Radio color='primary' />} label="Both" />
                            </RadioGroup>
                        </FormControl>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ flexGrow: 1 }} />
                            <Box sx={{ py: 2, display: 'flex' }}>
                                <CustomFormButton color="error" Text='İptal' Icon={<CancelIcon />} onClick={onCancel} />
                                <CustomFormButton Type='submit' disabled={isSubmitting} Text='Kaydet' Icon={<SaveIcon />} />
                            </Box>
                        </Box>

                    </form>
                )}
            </Formik>
        </>
    )
}