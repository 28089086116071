import { useEffect, useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import {
    Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core';
import { Cancel as CancelIcon, Save as SaveIcon } from '@material-ui/icons';


import { makeStyles } from '@material-ui/core/styles';

import { userList, userListResult } from '../data/actions/user'
import { logout } from '../utils/preferences'
import BasicTable from '../components/BasicTable'
import CustomFormButton from '../components/CustomFormButton'

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const Application = () => {
    const navigate = useNavigate();
    const classes = useStyles();
    const userListResponse = userListResult.use()

    const [users, setUsers] = useState([]);

    const processUserList = () => {
        if (userListResponse) {
            if (userListResponse.loading) {
                // show loading
            }
            else {
                console.log('userListResponse', userListResponse)
                if (userListResponse.result) {
                    const _userListRes = userListResponse.result

                    if (_userListRes.status === 200) {
                        if (_userListRes.data && _userListRes.data.success) {
                            setUsers(_userListRes.data.data)
                        }

                        // api returned error?
                    }
                    else {
                        if (_userListRes.status === 403) {
                            // token expired or malformed
                            // redirect to login

                            logout()
                            navigate('/login', { replace: true });
                        }

                        // other errors
                    }
                }
            }
        }

        // error
    }

    const didMount = async () => {
        await userList()
    }

    useEffect(() => {
        processUserList()
    }, [userListResponse.result])

    useEffect(() => {
        didMount()
    }, [])

    return (
        <>
            <Helmet>
                <title>Kullanıcılar</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center'
                }}
            >
                <div style={{ padding: 24 }}>
                    <BasicTable headCells={['İsim', 'soyisim', 'Email']} rows={users}  />
                </div>
            </Box>
        </>
    );
};

export default Application;
