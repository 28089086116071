import { useEffect, useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography
} from '@material-ui/core';
import { login, loginResult } from '../data/actions/login'
import { setAccessToken, getAccessToken } from '../utils/preferences'

import { CustomSnackBar } from '../components/custom'

const Login = () => {
  const [response, setResponse] = useState({
    isSuccess: false,
    message: null
  });

  const navigate = useNavigate();
  const loginResponse = loginResult.use()

  const checkLogin = () => {
    const accessToken = getAccessToken()

    if (accessToken) {
      navigate('/app/notification', { replace: true });
    }
  }

  useEffect(() => {
    checkLogin()
  }, [])

  useEffect(() => {
    if (loginResponse) {
      if (loginResponse.loading) {
        // show loading
      }
      else {
        if (loginResponse.result && loginResponse.result.data && loginResponse.result.data.accessToken) {
          setAccessToken(loginResponse.result.data.accessToken)

          navigate('/app/notification', { replace: true });
          return
        }
        else if(loginResponse.result) {
          setResponse({ isSuccess: false, message: 'Giriş Başarısız.' });
        }
      }
    }
    // error

  }, [loginResponse])

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('email geçersiz').max(255, 'maksimum karakter sayısı 255').
                required('email alanı boş geçilemez'),
              password: Yup.string().max(255, 'maksimum karakter sayısı 255').
                required('şifre alanı boş geçilemez')
            })}
            onSubmit={async (values) => {
              await login(values.email, values.password)
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Pragma Marketing Cloud
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
          <CustomSnackBar open={response.message != null} type={response.isSuccess ? 'success' : 'error'}
            handleClose={() => setResponse({ ...response, message: null })}
            message={response.message} />
        </Container>
      </Box>
    </>
  );
};

export default Login;
