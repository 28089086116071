import { entity } from 'simpler-state'
import { get, post } from '../client'

const appListInitialState = {
    loading: false,
    result: null
}

const appListAction = async () => {
    const response = await get('/app', true)
    return response
}

const appCreateAction = async (data) => {
    const response = await post('/app', data, true)
    return response;
}

const appDeleteAction = async (id) => {
    let data = { id };
    const response = await post('/app/delete', data, true)
    return response;
}

const appEditAction = async (data) => {
    const response = await post('/app/edit', data, true)
    return response;
}

export const appListResult = entity(appListInitialState)

export const appList = async () => {
    appListResult.set({
        loading: true,
        result: null
    })

    const result = await appListAction()

    appListResult.set({
        loading: false,
        result
    })
}

export const appCreate = async (data) => {

    appListResult.set({
        loading: true,
        result: null
    })

    const result = await appCreateAction(data);

    if (result.data.success) {
        const apps = await appListAction();
        appListResult.set({
            loading: false,
            result: apps
        });
    }

    return result;
}

export const appDelete = async (id) => {

    appListResult.set({
        loading: true,
        result: null
    })

    const result = await appDeleteAction(id);

    if (result.data.success) {
        const apps = await appListAction();
        appListResult.set({
            loading: false,
            result: apps
        });
    }

    return result;
}

export const appEdit = async (data) => {

    appListResult.set({
        loading: true,
        result: null
    })

    const result = await appEditAction(data);

    if (result.data.success) {
        const apps = await appListAction();
        appListResult.set({
            loading: false,
            result: apps
        });
    }

    return result;
}