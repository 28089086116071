import { useEffect, useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import {
    Box, Fab, LinearProgress, TextField
} from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';


import { makeStyles } from '@material-ui/core/styles';

import { appList, appCreate, appEdit, appDelete, appListResult } from '../data/actions/app'
import { logout, getAccessToken } from '../utils/preferences'
import EnhancedTable from '../components/EnhancedTable'
import { ConfirmationModal, FormModal } from '../components/modal'
import { ApplicationForm, CustomSnackBar } from 'src/components/custom';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const Application = () => {
    const navigate = useNavigate();
    const classes = useStyles();
    const appListResponse = appListResult.use()

    const [apps, setApps] = useState([])
    const [selectedApp, setSelected] = useState(null)
    const [headCells, setHeadCells] = useState([])
    const [openModal, setOpen] = useState('');
    const [response, setResponse] = useState({
        isSuccess: false,
        message: null
    });

    const handleClickOpen = (event, id) => {
        event.stopPropagation();
        let app = apps.filter(item => item.id == id)[0];
        setSelected(app);
        setOpen('modal-edit-form');
    };

    const handleClose = () => {
        setOpen('');
    };

    const saveForm = async (data) => {
        try {
            let res = await appCreate(data);
            if (res.data.success) {
                setOpen('');
                setResponse({ isSuccess: true, message: 'İşlem Başarılı.' });
            }
            else {
                setResponse({ isSuccess: false, message: 'Hata Oluştu.' });
            }
            console.log('respp', res)
        }
        catch (err) {
            setResponse({ isSuccess: false, message: 'Hata Oluştu.' });
        }
    }

    const editApp = async (data) => {
        try {
            let res = await appEdit(data);
            if (res.data.success) {
                setOpen('');
                setResponse({ isSuccess: true, message: 'İşlem Başarılı.' });
            }
            else {
                setResponse({ isSuccess: false, message: 'Hata Oluştu.' });
            }
            console.log('respp', res)
        }
        catch (err) {
            setResponse({ isSuccess: false, message: 'Hata Oluştu.' });
        }
    }

    const deleteApp = async () => {
        try {
            let res = await appDelete(selectedApp.id);
            if (res.data.success) {
                setOpen('');
                setResponse({ isSuccess: true, message: 'İşlem Başarılı.' });
            }
            else {
                setResponse({ isSuccess: false, message: 'Hata Oluştu.' });
            }
            console.log('respp', res)
        }
        catch (err) {
            setResponse({ isSuccess: false, message: 'Hata Oluştu.' });
        }
    }

    const handleConfirmationOpen = (event, id) => {
        let app = apps.filter(item => item.id == id)[0];
        setSelected(app);
        event.stopPropagation();
        setOpen('modal-confirmation');
    };

    const handleConfirmationClose = () => {
        setOpen('');
    };

    const processAppList = () => {
        if (appListResponse) {
            if (appListResponse.loading) {
                // show loading
            }
            else {
                console.log('appListResponse', appListResponse)
                if (appListResponse.result) {
                    const _appListRes = appListResponse.result

                    if (_appListRes.status === 200) {
                        if (_appListRes.data && _appListRes.data.success) {
                            const appsResult = _appListRes.data.data
                            const newArray = appsResult.map(({numberOfTokens, ...keepAttrs}) => keepAttrs)
                            setApps(newArray)
                        }

                        // api returned error?
                    }
                    else {
                        if (_appListRes.status === 403) {
                            // token expired or malformed
                            // redirect to login

                            logout()
                            navigate('/login', { replace: true });
                        }

                        // other errors
                    }
                }
            }
        }

        // error
    }

    const didMount = async () => {
        setHeadCells([
            {
                id: 'name',
                numeric: false,
                disablePadding: false,
                label: 'Uygulama',
            },
            {
                id: 'storeId',
                numeric: false,
                disablePadding: false,
                label: 'Store id',
            },
            {
                id: 'iconUrl',
                numeric: false,
                disablePadding: false,
                label: 'İkon',
            },
            {
                id: 'platform',
                numeric: false,
                disablePadding: false,
                label: 'Platform',
            }
        ]);
        await appList()
    }

    useEffect(() => {
        processAppList()
    }, [appListResponse.result])

    useEffect(() => {
        didMount()
    }, [])

    return (
        <>
            <Helmet>
                <title>Bildirim Gönder</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center'
                }}
            >
                <div style={{ padding: 24 }}>
                    <EnhancedTable
                        title='Uygulamalar'
                        rows={apps}
                        headCells={headCells}
                        onEdit={(e, id) => { handleClickOpen(e, id) }}
                        onDelete={(e, id) => { handleConfirmationOpen(e, id) }} />

                    <Fab style={{ position: 'absolute', bottom: 16, right: 16, }}
                        aria-label="Ekle" color='primary'
                        onClick={() => setOpen('modal-create-form')}
                    >
                        <AddIcon />
                    </Fab>
                </div>
            </Box>
            <FormModal open={openModal == 'modal-edit-form'} title='Düzenle' onClose={handleClose}>
                <ApplicationForm onSave={(data) => editApp(data)} onCancel={handleClose} data={selectedApp} />
            </FormModal>

            <FormModal open={openModal == 'modal-create-form'} title='Ekle' onClose={handleClose}>
                <ApplicationForm onSave={(data) => saveForm(data)} onCancel={handleClose} />
            </FormModal>

            <ConfirmationModal
                id="ringtone-menu"
                keepMounted
                open={openModal == 'modal-confirmation'}
                description='Seçili uygulama Silinecek. Onaylıyormusunuz?'
                onCancel={handleConfirmationClose}
                onOk={deleteApp}
            // value={value}
            />
            <CustomSnackBar open={response.message != null} type={response.isSuccess ? 'success' : 'error'}
                handleClose={() => setResponse({ ...response, message: null })}
                message={response.message} />
            {appListResult.loading ? <Box sx={{ width: '100%', position: 'absolute', top: 64, right: 0 }}>
                <LinearProgress sx={{ height: 10 }} color="success" />
            </Box> : null}

        </>
    );
};

export default Application;
