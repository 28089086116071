import * as React from 'react';

import {
    Button, Snackbar, Alert
} from '@material-ui/core';

export default function CustomSnackBar(props) {

    const { message, open, handleClose, type } = props;

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={open}
            onClose={handleClose}
            autoHideDuration={5000}
        >
            <Alert severity={type} elevation={6} variant="filled" >{message}</Alert>
        </Snackbar>
    )
}