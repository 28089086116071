import axios from 'axios';
import { API_REQ_TIMEOUT } from '../config/constants'
import { getAccessToken } from '../utils/preferences'

const requestInterceptor = (req) => {
  console.log('Request: ', req);
  return req;
};

const responseInterceptor = (res) => {
  console.log('Response: ', res);
  return res;
};

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: API_REQ_TIMEOUT,
});

axiosClient.interceptors.request.use(requestInterceptor);
axiosClient.interceptors.response.use(responseInterceptor);

const makeRequest = async (url, method = 'GET', body, sendAuthHeader = false) => {
  try {
    let headers = {}
    if (sendAuthHeader) {
      headers = {
        'Authorization': getAccessToken()
      }
    }

    let response
    if(method === 'GET') {
      response = await axiosClient.get(url, {
        headers
      })
    }
    else if(method === 'POST') {
      response = await axiosClient.post(url, body, {
        headers
      })
    }

    return response
  } 
  catch (error) {
    // console.log(error.response.data)
    // console.log(error.response.status)
    // console.log(error.response.headers)

    return error && error.response
  }
}

const get = async (url, sendAuthHeader = false) => {
  const response = await makeRequest(url, 'GET', null, sendAuthHeader)
  return response
}

const post = async (url, body, sendAuthHeader = false) => {
  const response = await makeRequest(url, 'POST', body, sendAuthHeader)
  return response
}

export default axiosClient
export {
  get,
  post
}