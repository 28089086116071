import * as React from 'react';
import PropTypes from 'prop-types';

import {
    DialogTitle, DialogContent, DialogActions, Dialog, Typography
} from '@material-ui/core';

import { Cancel as CancelIcon, Save as SaveIcon, Add as AddIcon } from '@material-ui/icons';
import CustomFormButton from '../CustomFormButton'


export default function FormModal(props) {
    const { children, onClose, onOpen, open, title, ...other } = props;
    const radioGroupRef = React.useRef(null);


    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        onOpen();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <Typography variant="h2" component="div">
                    {title}
                </Typography>
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            {/* <DialogActions>
                <CustomFormButton color="error" Text='İptal' Icon={<CancelIcon />} onClick={handleCancel} />
                <CustomFormButton Text='Kaydet' Icon={<SaveIcon />} onClick={handleOk} />
            </DialogActions> */}
        </Dialog>
    );
}

FormModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};